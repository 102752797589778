import { Formik } from "formik";
import React from "react";
import { DivFlex } from "../../App.style";
import {
  Form,
  NFastTitle,
  P,
  SideLeftCreateCard,
  SideRightCreateCard,
} from "../enterprise/create/style";
import * as Yup from "yup";
import { YupErrors } from "../../Consts/YupErrors";
import { Button, TextField, Typography } from "@mui/material";
import { useSearchParams, Link } from "react-router-dom";
import AuthService from "../../services/auth/auth.service";
import useAuthenticate from "../../hooks/useAuthenticate";
import useSetError from "../../hooks/useSetError";
export function Login(): JSX.Element {
  const LoginValidate = Yup.object().shape({
    email: Yup.string().email(YupErrors.email).required(YupErrors.required),
    password: Yup.string().required(YupErrors.required),
  });
  const { login } = useAuthenticate();
  const [params] = useSearchParams();
  const setErrror = useSetError()
  const authService = new AuthService();

  async function handleSubmit(data: { email: string; password: string }): Promise<void> {
    try {
      await authService.login(data);
      login();
    } catch (error) {
        setErrror(error)
    }
  }

  return (
    <DivFlex>
      <SideLeftCreateCard>
        <NFastTitle>NFAST</NFastTitle>
        <P>
          {" "}
          uma solução
          <br />
          rápida e ágil <br />
          para o seu negócio{" "}
        </P>
      </SideLeftCreateCard>
      <SideRightCreateCard>
        <Formik
          initialValues={{ email: params.get("email") ?? "", password: "" }}
          onSubmit={handleSubmit}
          validationSchema={LoginValidate}
        >
          {({
            values,
            errors,
            touched,
            handleChange,
            handleBlur,
            handleSubmit,
            isSubmitting,
            isValid,
            dirty,
          }) => (
            <Form onSubmit={handleSubmit}>
              <Typography variant="h1" fontSize={40}>
                Login
              </Typography>

              <TextField
                variant="outlined"
                type="email"
                label="Email"
                name="email"
               helperText={errors.email}
                value={values.email}
                onChange={handleChange}
                onBlur={handleBlur}
              />
              <TextField
                variant="outlined"
                type="password"
                label="Senha"
                name="password"
                helperText={errors.password}
                value={values.password}
                onChange={handleChange}
                onBlur={handleBlur}
              />
              <Button
                sx={{ height: 80 }}
                variant="contained"
                disabled={!(isValid && !isSubmitting && dirty)}
                type="submit"
              >
                Entrar
              </Button>
              <Link to="/cadastrar">
                <Button
                  sx={{ height: 80, width: "100%" }}
                  variant="outlined"
                  color="secondary"
                  type="button"
                >
                  Cadastrar
                </Button>
              </Link>
            </Form>
          )}
        </Formik>
      </SideRightCreateCard>
    </DivFlex>
  );
}
