
import { Add } from "@mui/icons-material";
import React, { useState } from "react";
import { Link } from "react-router-dom";
import { FabStyled } from "./styles"

export default function FloatButton({path}: {path:string}):JSX.Element{
    const [showAdd, setShowAdd] = useState(false);
    return(
        <Link to={path}>
        <FabStyled onMouseEnter={() => {setShowAdd(true)}} onMouseLeave={() => {setShowAdd(false)}} color="primary" variant={showAdd? "extended": "circular"} >
            {showAdd ? "Adicionar " : ""}
            <Add />
         </FabStyled>
        </Link>
       
    )
}