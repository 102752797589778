import { createEnterpriseDto, enterpriseSubmit } from "../../DTOs/enterprise.dto";
import { findUser } from "../../DTOs/user.dto";
import BaseApiService from "../BaseApiService";

export class EnterpriseService extends BaseApiService{
    
    constructor(){
        super('enterprises')
    
    }
    async createEnterprise(data:enterpriseSubmit): Promise<{email:string}>{

        try {
            const enterprise: createEnterpriseDto = {
                name: data.name,
                administrator: {
                    email: data.email,
                    password: data.password,
                    name: data.administratorName
                }
            }
           return (await this.nfastApi.post<{email:string}>('',enterprise)).data;
        } catch (error) {
           throw this.handleError(error);
        }
       
    }
    async getAllUsers(): Promise<findUser[]>{
        try{
            return (await this.nfastApi.get<findUser[]>('/users')).data;
        } catch (error) {
            throw this.handleError(error);
        }
    }

  
    
}