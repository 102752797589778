import React from "react";
import {
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import { Formik } from "formik";
import { createUserValidator } from "../../Validators/userValidators";
import useUsers from "../../hooks/useUsers";
import { FormUser } from "./styles";

export default function CreateUser(): JSX.Element {
  const { createUser } = useUsers();

  return (
    <div>
      <Formik
        initialValues={{ name: "", email: "", password: "", role: "" }}
        onSubmit={createUser}
        validationSchema={createUserValidator}
      >
        {({
          values,
          errors,
          handleChange,
          handleBlur,
          isValid,
          dirty,
          touched,
          handleSubmit,
        }) => (
          <FormUser onSubmit={handleSubmit}>
            <TextField
              label="Nome"
              name="name"
              error={errors.name != null && touched.name}
              helperText={touched.name === true && errors.name}
              value={values.name}
              onChange={handleChange}
              onBlur={handleBlur}
            />
            <TextField
              label="Email"
              type="email"
              name="email"
              error={errors.email != null && touched.email}
              helperText={touched.email === true && errors.email}
              value={values.email}
              onChange={handleChange}
              onBlur={handleBlur}
            />
            <TextField
              label="Senha"
              type="password"
              error={errors.password != null && touched.password}
              helperText={touched.password === true && errors.password}
              name="password"
              value={values.password}
              onChange={handleChange}
              onBlur={handleBlur}
            />
            <FormControl
              fullWidth
              variant="outlined"
              error={errors.role != null && touched.role}
            >
              <InputLabel id="labelID">Papel</InputLabel>
              <Select
                labelId="labelID"
                id="teste"
                value={values.role}
                name="role"
                onChange={handleChange}
                onBlur={handleBlur}
              >
                <MenuItem value="administrator">Administrador</MenuItem>
                <MenuItem value="employer">Funcionário</MenuItem>
                <MenuItem value="reader">Leitor</MenuItem>
              </Select>
            </FormControl>
            <Button
              variant="contained"
              disabled={!(isValid && dirty)}
              type="submit"
            >
              Cadastrar
            </Button>
          </FormUser>
        )}
      </Formik>
    </div>
  );
}
