import React, { useEffect } from "react";
import {
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import { Formik } from "formik";
import { useParams } from "react-router-dom";
import { FormUser } from "./styles";
import useUsers from "../../hooks/useUsers";
import { editUserValidate } from "../../Validators/userValidators";
export default function EditUser(): JSX.Element {
  const { id } = useParams<{ id: string }>();
  const { user, getUser, edit } = useUsers();

  useEffect(() => {
    void getUser(id as string);
  }, [id, getUser]);

  return (
    <div>
      {user !== undefined && (
        <Formik
          initialValues={{
            name: user.name,
            email: user.email,
            role: user.role,
          }}
          onSubmit={edit}
          validationSchema={editUserValidate}
        >
          {({
            values,
            errors,
            handleChange,
            handleBlur,
            isValid,
            dirty,
            touched,
            handleSubmit,
          }) => (
            <FormUser onSubmit={handleSubmit}>
              <TextField
                label="Nome"
                name="name"
                error={errors.name != null && touched.name}
                helperText={touched.name === true && errors.name}
                value={values.name}
                onChange={handleChange}
                onBlur={handleBlur}
              />
              <TextField
                label="Email"
                type="email"
                name="email"
                error={errors.email != null && touched.email}
                helperText={touched.email === true && errors.email}
                value={values.email}
                onChange={handleChange}
                onBlur={handleBlur}
              />
              <FormControl
                fullWidth
                variant="outlined"
                error={errors.role != null && touched.role}
              >
                <InputLabel id="labelID">Papel</InputLabel>
                <Select
                  labelId="labelID"
                  id="teste"
                  value={values.role}
                  name="role"
                  onChange={handleChange}
                  onBlur={handleBlur}
                >
                  <MenuItem value="administrator">Administrador</MenuItem>
                  <MenuItem value="employer">Funcionário</MenuItem>
                  <MenuItem value="reader">Leitor</MenuItem>
                </Select>
              </FormControl>
              <Button variant="contained" disabled={!isValid} type="submit">
                Salvar
              </Button>
            </FormUser>
          )}
        </Formik>
      )}
    </div>
  );
}
