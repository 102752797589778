import { Cancel } from "@mui/icons-material";
import {
  Button,
  IconButton,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Table,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from "@mui/material";

import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import { ErrorContext } from "../../Context/ErrorContext";
import OldApiService from "../../services/oldApi/OldApi.service";

export default function Deliveries(): JSX.Element {
  const [setLogged] = useState(false);
  const [invoices, setInvoices] = useState<any[]>([]);
  const oldApiService = useMemo(() => new OldApiService(), []);
  const [dialog, setDialog] = useState(false);
  const [invoiceId, setInvoiceId] = useState(0);
  const [protocolNumber, setProtocolNumber] = useState("");
  const [reasonCancel, setReasonCancel] = useState("");

  const { setMessageError } = useContext(ErrorContext);

  const labels = [
    {
      name: "_id",
      label: "Id da Nota",
    },
    {
      name: "NFENumber",
      label: "Numero da Nota",
    },
  ];
 
  const getAll = useCallback(async () => {
    const customers = await oldApiService.getAllNotices();
    customers.map((customer: any) => {
      customer._id = customer.id;
      return customer;
    });
    setInvoices(customers);
  }, [oldApiService]);
  useEffect(() => {
    void getAll();
  }, [setLogged, getAll]);

  const openDialog = (id: any): void => {
    setInvoiceId(id);
    setDialog(true);
  };

  const cancelInvoice = async (): Promise<void> => {
    try {
      await oldApiService.cancel(invoiceId, {
        protocol_number: protocolNumber,
        reason: reasonCancel,
      });
      setDialog(false);
    } catch (error) {
      setMessageError(error);
    }
  };

  return (
    <div>
      <div>
        <TableContainer>
          <Table>
            <TableHead>
              <TableRow>
                {labels.map((value) => (
                  <TableCell
                    key={value.label}
                    sx={{ fontWeight: 700, fontSize: 25 }}
                  >
                    {value.label}
                  </TableCell>
                ))}
                <TableCell />
              </TableRow>
            </TableHead>
            <TableBody>
              {invoices.map((value, key) => (
                <TableRow key={value._id}>
                  {labels.map((label) => (
                    <TableCell
                      // eslint-disable-next-line @typescript-eslint/restrict-plus-operands
                      key={value._id + label.name}
                      sx={{ fontSize: 20 }}
                    >
                      {value[label.name]}
                    </TableCell>
                  ))}
                  <TableCell>
                    <IconButton
                      color="error"
                      size="large"
                      onClick={() => {
                        openDialog(value._id);
                      }}
                    >
                      <Cancel />
                    </IconButton>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
        <Dialog open={dialog}>
          <DialogTitle>Cancelar Nota Fiscal</DialogTitle>
          <DialogContent>
            Para cancelar a nota emitida no RS, você tem até 7 dias para efetuar
            o cancelamento, você vai precisar do protocolo de autorização
            <TextField
              autoFocus
              margin="dense"
              label="Número de protocolo de autorização"
              fullWidth
              variant="standard"
              onChange={(e) => {
                setProtocolNumber(e.target.value);
              }}
            />
            <TextField
              autoFocus
              margin="dense"
              label="Motivo do cancelamento"
              fullWidth
              variant="standard"
              onChange={(e) => {
                setReasonCancel(e.target.value);
              }}
            />
          </DialogContent>

          <DialogActions>
            <Button
              onClick={() => {
                setDialog(false);
              }}
            >
              Sair
            </Button>
            <Button onClick={() => {void cancelInvoice()}}>Cancelar Nota</Button>
          </DialogActions>
        </Dialog>
      </div>
    </div>
  );
}
