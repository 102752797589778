import React from "react";
import { createBrowserRouter } from "react-router-dom";
import PrivateRoute from "./Components/PrivateRoutes/PrivateRoute";
import EnterpriseDash from "./pages/enterprise/dash";
import CreateUser from "./pages/users/create";
import Users from "./pages/users";
import EditUser from "./pages/users/edit";
import Emitters from "./pages/emitters";
import CreateEmitter from "./pages/emitters/create";
import EditEmitter from "./pages/emitters/edit";
import Customers from "./pages/customers";
import EditOldCustomer from "./pages/customers/edit";
import Deliveries from "./pages/Deliveries";
import { Login } from "./pages/login";
import CreateEnterprise from "./pages/enterprise/create";

const routes = createBrowserRouter([
  {
    path: "/",
    element: <PrivateRoute />,
    children: [
      {
        path: "",
        element: <EnterpriseDash />,
        children: [
          {
            path: "usuarios",
            element: <PrivateRoute />,
            children: [
              {
                index: true,
                element: <Users />,
              },
              {
                path: "criar",
                element: <CreateUser />,
              },
              {
                path: "editar/:id",
                element: <EditUser />,
              },
            ],
          },
          {
            path: "emissores",
            element: <PrivateRoute />,
            children: [
              {
                index: true,
                element: <Emitters />,
              },
              {
                path: "criar",
                element: <CreateEmitter />,
              },
              {
                path: "editar/:id",
                element: <EditEmitter />,
              },
            ],
          },
          {
            path: "clientes",
            children: [
              {
                index: true,
                element: <Customers />,
              },
              {
                path: "editar/:id",
                element: <EditOldCustomer />,
              },
            ],
          },
          {
            path: "entregas",
            element: <Deliveries />,
          },
        ],
      },
    ],
  },
  {
    path: 'login',
    element: <Login />
  },
  {
    path: 'cadastrar',
    element: <CreateEnterprise />
  }
]);
export default routes;
