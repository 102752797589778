import axios from 'axios';

export const NfastApi = (route:string) => {
    const baseUrl = process.env.REACT_APP_NFAST_API;
    return axios.create({
        baseURL: baseUrl + route,
        timeout: 1000,
        headers:{
            'Authorization': `Bearer ${localStorage.getItem('token')}`
        }
    })
}