import "./App.css";
import React from "react";
import { RouterProvider } from "react-router-dom";
import { AuthProvider } from "./Context/AuthContext";
import { ErrorProvider } from "./Context/ErrorContext";
import routes from "./routes";

function App(): JSX.Element {
  return (
    <AuthProvider>
      <ErrorProvider>
        <RouterProvider router={routes} />
      </ErrorProvider>
    </AuthProvider>
  );
}

export default App;
