import * as Yup from 'yup';
import { YupErrors } from '../Consts/YupErrors';
const documentRegex =
  /(^\d{3}\.\d{3}\.\d{3}-\d{2}$)|(^\d{2}\.\d{3}\.\d{3}\/\d{4}-\d{2}$)/;
const createUserValidate = Yup.object().shape({
  corporate_name: Yup.string()
    .min(2, YupErrors.shortName)
    .required(YupErrors.required),
  state_subscription: Yup.number().required(YupErrors.required),
  document: Yup.string()
    .matches(documentRegex, YupErrors.invalidDocument)
    .required(YupErrors.required),
  tax_regime_code: Yup.string().required(YupErrors.required),
  cep: Yup.string().required(YupErrors.required),
  public_place: Yup.string().required(YupErrors.required),
  district: Yup.string().required(YupErrors.required),
  city: Yup.string().required(YupErrors),
  state: Yup.string().required(YupErrors.required),
});
const editEmitterValidate = Yup.object().shape({
  corporate_name: Yup.string().min(2, YupErrors.shortName),
  state_subscription: Yup.number(),
  document: Yup.string().matches(documentRegex, YupErrors),
});
export {createUserValidate, editEmitterValidate }