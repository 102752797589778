import { Button, TextField, Typography } from "@mui/material";
import { Formik } from "formik";
import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import { useNavigate, useParams } from "react-router-dom";
import { ErrorContext } from "../../Context/ErrorContext";
import OldApiService from "../../services/oldApi/OldApi.service";
import { Form } from "../enterprise/create/style";

export default function EditOldCustomer(): JSX.Element {
  const [customer, setCustomer] = useState<any>({});
  const customerService = useMemo(() => new OldApiService(), []);
  const { id } = useParams<{ id: string }>();
  const { setMessageError } = useContext(ErrorContext);
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const getCustomer = useCallback(async (): Promise<void> => {
    try {
      if (id != null) {
        const customer = await customerService.find(id);
        setCustomer(customer);
        setLoading(false);
      }
    } catch (error) {
      setMessageError(error);
    }
  }, [id, setMessageError, customerService]);
  useEffect(() => {
    void getCustomer();
  }, [id, getCustomer]);
  async function handleSubmit(data: any): Promise<void> {
    try {
      if (id != null) {
        await customerService.update(id, data, customer.address.id);
        navigate(-1);
      }
    } catch (error) {
      setMessageError(error);
    }
  }

  return (
    <div>
      {loading ? (
        <div></div>
      ) : (
        <Formik
          initialValues={{
            company_name: customer.company_name,
            document: customer.document,
            state_subscription: customer.state_subscription,
            email: customer.email,
            public_place: customer.address.public_place,
            number: customer.address.number,
            district: customer.address.district,
            cep: customer.address.CEP,
          }}
          onSubmit={handleSubmit}
        >
          {({
            values,
            errors,
            handleChange,
            handleBlur,
            isValid,
            dirty,
            touched,
            handleSubmit,
          }) => (
            <Form onSubmit={handleSubmit}>
              <TextField
                label="Nome"
                name="company_name"
                type="text"
                value={values.company_name}
                onChange={handleChange}
                onBlur={handleBlur}
                sx={{ marginTop: 2 }}
              />
              <TextField
                label="Documento"
                name="document"
                value={values.document}
                onChange={handleChange}
                onBlur={handleBlur}
                sx={{ marginTop: 2 }}
              />
              <TextField
                label="Inscrição Estadual"
                name="state_subscription"
                value={values.state_subscription}
                onChange={handleChange}
                onBlur={handleBlur}
                sx={{ marginTop: 2 }}
              />
              <TextField
                label="Email"
                name="email"
                value={values.email}
                onChange={handleChange}
                onBlur={handleBlur}
                sx={{ marginTop: 2 }}
              />
              <Typography sx={{ paddingTop: 5 }}>Endereço</Typography>
              <TextField
                label="Logradouro"
                name="public_place"
                value={values.public_place}
                onChange={handleChange}
                onBlur={handleBlur}
                sx={{ marginTop: 2 }}
              />
              <TextField
                label="Número"
                name="number"
                value={values.number}
                onChange={handleChange}
                onBlur={handleBlur}
                sx={{ marginTop: 2 }}
              />
              <TextField
                label="Bairro"
                name="district"
                value={values.district}
                onChange={handleChange}
                onBlur={handleBlur}
                sx={{ marginTop: 2 }}
              />
              <TextField
                label="CEP"
                name="cep"
                value={values.cep}
                onChange={handleChange}
                onBlur={handleBlur}
                sx={{ marginTop: 2 }}
              />
              <Button
                variant="contained"
                sx={{ marginTop: 2, height: 50 }}
                disabled={!isValid}
                type="submit"
              >
                Salvar
              </Button>
            </Form>
          )}
        </Formik>
      )}
    </div>
  );
}
