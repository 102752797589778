import {
  Button,
  Divider,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import { Formik } from "formik";
import React, { type FocusEvent } from "react";
import { getCepInfo } from "../../services/CepService";
import { maskCep, maskDocument } from "../../Utils/Masks";
import { createUserValidate } from "../../Validators/emitterValidators";
import useEmitters from "../../hooks/useEmitters";
import { Form } from "../../App.style";
import useSetError from "../../hooks/useSetError";

export default function CreateEmitter(): JSX.Element {
  const { create } = useEmitters();
  const setError = useSetError();
  const loadCity = async (
    e: FocusEvent<HTMLInputElement | HTMLTextAreaElement>,
    handleBlur: (e: React.FocusEvent) => void,
    setFieldValue: (field: string, value: any) => void
  ): Promise<void> => {
    try {

      if (e.target.value.length < 9) return;
      const cepInfo = await getCepInfo(e.target.value);
      if(cepInfo === undefined)
        throw new Error('Cidade não encontrada');
      setFieldValue("public_place", cepInfo.logradouro);
      setFieldValue("district", cepInfo.bairro);
      setFieldValue("city", cepInfo.localidade);
      setFieldValue("state", cepInfo.uf);
      handleBlur(e);
    } catch (error) {
      setError(error);
    }
  };

  return (
    <div>
      <Formik
        initialValues={{
          corporate_name: "",
          state_subscription: "",
          number: "",
          document: "",
          tax_regime_code: "",
          cep: "",
          public_place: "",
          city: "",
          state: "",
          district: "",
        }}
        onSubmit={create}
        validationSchema={createUserValidate}
      >
        {({
          values,
          errors,
          handleChange,
          handleBlur,
          isValid,
          dirty,
          touched,
          handleSubmit,
          setFieldValue,
        }) => (
          <Form onSubmit={handleSubmit}>
            <TextField
              label="Razão Social"
              name="corporate_name"
              error={errors.corporate_name != null && touched.corporate_name}
              helperText={
                touched.corporate_name === true && errors.corporate_name
              }
              value={values.corporate_name}
              onChange={handleChange}
              onBlur={handleBlur}
            />
            <TextField
              label="Inscrição Estadual"
              name="state_subscription"
              error={
                errors.state_subscription != null && touched.state_subscription
              }
              helperText={
                touched.state_subscription === true && errors.state_subscription
              }
              value={values.state_subscription}
              onChange={handleChange}
              onBlur={handleBlur}
            />

            <TextField
              label="Documento"
              type="document"
              error={errors.document != null && touched.document}
              helperText={touched.document === true && errors.document}
              name="document"
              value={maskDocument(values.document)}
              onChange={handleChange}
              onBlur={handleBlur}
            />

            <FormControl
              fullWidth
              variant="outlined"
              error={errors.tax_regime_code != null && touched.tax_regime_code}
            >
              <InputLabel id="labelID">Código de Regime Tributário</InputLabel>
              <Select
                labelId="labelID"
                id="teste"
                value={values.tax_regime_code}
                name="tax_regime_code"
                onChange={handleChange}
                onBlur={handleBlur}
              >
                <MenuItem value={1}>1 | Simples Nacional</MenuItem>
                <MenuItem value={2}>
                  2 | Simples Nacional - excesso de sublimite de receita bruta
                </MenuItem>
                <MenuItem value={3}>3 | Regime Normal</MenuItem>
                <MenuItem value={4}>4 | Simples Nacional - Mei</MenuItem>
              </Select>
            </FormControl>

            <Typography sx={{ paddingTop: 5 }}>Endereço</Typography>
            <Divider />
            <TextField
              label="CEP"
              error={errors.cep != null && touched.cep}
              helperText={touched.cep === true && errors.cep}
              name="cep"
              value={maskCep(values.cep)}
              onChange={handleChange}
              onBlur={(e) => {
                void loadCity(e, handleBlur, setFieldValue);
              }}
            />
            <TextField
              label="Logradouro"
              error={errors.public_place != null && touched.public_place}
              helperText={touched.public_place === true && errors.public_place}
              name="public_place"
              value={values.public_place}
              onChange={handleChange}
              onBlur={handleBlur}
            />
            <TextField
              label="Número"
              error={errors.number != null && touched.number}
              helperText={touched.number === true && errors.number}
              name="number"
              value={values.number}
              onChange={handleChange}
              onBlur={handleBlur}
            />
            <TextField
              label="Bairro"
              type="district"
              error={errors.district != null && touched.district}
              helperText={touched.district === true && errors.district}
              name="district"
              value={values.district}
              onChange={handleChange}
              onBlur={handleBlur}
            />
            <TextField
              label="Cidade"
              type="city"
              error={errors.city != null && touched.city}
              helperText={touched.city === true && errors.city}
              name="city"
              value={values.city}
              onChange={handleChange}
              onBlur={handleBlur}
            />
            <TextField
              label="Estado"
              type="state"
              error={errors.state != null && touched.state}
              helperText={touched.state === true && errors.state}
              name="state"
              value={values.state}
              onChange={handleChange}
              onBlur={handleBlur}
            />
            <Button
              variant="contained"
              sx={{ marginTop: 2, height: 50 }}
              disabled={!(isValid && dirty)}
              type="submit"
            >
              Cadastrar
            </Button>
          </Form>
        )}
      </Formik>
    </div>
  );
}
