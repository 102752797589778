import { createEmitter, editEmitter, findEmitter } from "../../DTOs/emitter.dto";
import { formEditEmitter, formEmitter } from "../../Entities/emitter.entity";
import BaseApiService from "../BaseApiService";

export class EmittersService extends BaseApiService{
    constructor(){
        super("emitters")
    }

    async create(emitter:formEmitter){
        try {
            const emitterToSend : createEmitter= {
                corporate_name:emitter.corporate_name,
                document: emitter.document,
                tax_regime_code: emitter.tax_regime_code,
                state_subscription: emitter.state_subscription,
                address: {
                    public_place: emitter.public_place,
                    number: emitter.number,
                    city: emitter.city,
                    cep: emitter.cep,
                    state: emitter.state,
                    district: emitter.district

                }
            }
            await this.nfastApi.post('',emitterToSend)
        } catch (error) {
            throw this.handleError(error)
        }
    }
    async getAll(){
        try {
            const { data } = await this.nfastApi.get('')
            return data;
        } catch (error) {
            throw this.handleError(error);
        }
    }
    async findOne(id:string): Promise<findEmitter>{
        try {
            const {data} = await this.nfastApi.get<findEmitter>(`/${id}`);
            return data;
        } catch (error) {
            throw this.handleError(error)
        }
    }
    async update(id:string,data:formEditEmitter){
       
        try {

            const emitterToSend : editEmitter= {
                corporate_name:data.corporate_name,
                document: data.document,
                tax_regime_code: data.tax_regime_code,
                state_subscription: data.state_subscription,
                address: {
                    public_place: data.public_place,
                    number: data.number,
                    city: data.city,
                    cep: data.cep,
                    state: data.state,
                    district: data.district
    
                }
            }

            await this.nfastApi.put(`/${id}`,emitterToSend);
        } catch (error) {
            throw this.handleError(error)
        }   
    }

    async delete(id:string){
        try {
            await this.nfastApi.delete(`/${id}`);
        } catch (error) {
            throw this.handleError(error)
        }
    }
}