import React, { useEffect } from "react";
import FloatButton from "../../Components/FloatButton";
import DataTable from "../../Components/DataTable";
import useEmitters from "../../hooks/useEmitters";
export default function Emitters(): JSX.Element {
  const { emitters, getAll, deleteEmitter } = useEmitters();
  const label = [
    {
      name: "corporate_name",
      label: "Razão Social",
    },
    {
      name: "tax_regime_code",
      label: "CRT",
    },
    {
      name: "document",
      label: "Documento",
    },
    {
      name: "state_subscription",
      label: "Inscrição Estadual",
    },
  ];

  useEffect(() => {
    void getAll();
  }, [getAll]);
  return (
    <div>
      <DataTable data={emitters} labels={label} onDelete={deleteEmitter} />
      <FloatButton path="criar" />
    </div>
  );
}
