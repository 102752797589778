import {  Fab } from "@mui/material";
import styled from "styled-components";

export const ListItem =  styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    font-size: 20px;
    margin-right: 20px;
    margin-left: 20px;
`
export const FabStyled = styled(Fab)`
    position: absolute !important;
    right: 40px;
    bottom: 40px;

`