import { useCallback, useMemo, useState } from "react";
import UsersService from "../services/users/UsersService";
import { type editUser, type createUser, type findUser } from "../DTOs/user.dto";
import useSetError from "./useSetError";
import { EnterpriseService } from "../services/enterprise/enterprise.service";
import { useNavigate } from "react-router-dom";

export default function useUsers(): {
  user?: findUser;
  users: findUser[];
  deleteUser: (id: string) => Promise<void>;
  createUser: (user: createUser) => Promise<void>;
  getAllUsers: () => Promise<void>;
  getUser: (id: string) => Promise<void>;
  edit:(userEdited: editUser) => Promise<void>;
} {
  const setError = useSetError();
  const navigate = useNavigate();
  const [users, setUsers] = useState<findUser[]>([]);
  const [user, setUser] = useState<findUser | undefined>();

  const enterpriseService = useMemo(() => new EnterpriseService(), []);
  const userService = useMemo(() => new UsersService(), []);

  const getAllUsers = useCallback(async (): Promise<void> => {
    try {
      let users = await enterpriseService.getAllUsers();

      users = users.map((user: findUser) => {
        switch (user.role) {
          case "administrator":
            user.role = "Administrador";
            break;
          case "employer":
            user.role = "Funcionário";
            break;
          case "reader":
            user.role = "Leitor";
            break;
        }
        return user;
      });
      setUsers(users);
    } catch (error) {
      setError(error);
    }
  }, [setError, enterpriseService]);

  const getUser = useCallback(async (id: string): Promise<void> => {
    try {
      const user = await userService.findOneUser(id);
      setUser(user);
    } catch (error) {
      setError(error);
    }
  }, []);
  const edit= async (userEdited: editUser): Promise<void> => {
    try {
      if (user === undefined) {
        setError("Usuario não existente");
        return;
      }
      await userService.updateUser(user._id, userEdited);
      navigate(-1);
    } catch (error) {
      setError(error)
    }
  };
  const deleteUser = async (id: string): Promise<void> => {
    try {
      await userService.deleteUser(id);
      setUsers(users.filter((user) => user._id !== id));
    } catch (error) {
      setError(error);
    }
  };
  const createUser = async (user: createUser): Promise<void> => {
    try {
      await userService.createUser(user);
      navigate(-1);
    } catch (error) {
      setError(error);
    }
  };

  return { user, users, deleteUser, createUser, getAllUsers, getUser, edit };
}
