/* eslint-disable @typescript-eslint/lines-between-class-members */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
import axios, { AxiosError } from "axios";
import { getCepInfo } from "../CepService";

export default class OldApiService {
    api = axios.create({
        baseURL: 'https://pedromietlicki.com.br',
        timeout: 5000,
        headers:{
            // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
            'Authorization': `Bearer ${localStorage.getItem('token')}`
        }
    })

    

    async getAll(): Promise<any[]>{
        try {
            const {data} =  await this.api.get('/clients');
           
            return data;
        } catch (error) {
            
            throw this.handleError(error)
        }
    }

    async getAllNotices(): Promise<any[]>{
        try {
            const {data} =  await this.api.get('/deliveries');
            return data.sort((a:any ,b:any)=>{
                if(parseInt(a.id) < parseInt(b.id)) 
                    return 1
                else 
                    return -1
            });
        } catch (error) {
            throw this.handleError(error)
        }
    }

    async cancel(id:number,data:any): Promise<void>{
        try {
            await this.api.post(`/cancelamento/${id}`,data);
        } catch (error) {
            this.handleError(error);
        }
    }
    
    async find(id:string){
        try {
            const {data} = await this.api.get(`/clients/${id}`)
            // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
            const addressData =  (await this.api.get(`/adresses/${data.address_id}`)).data;
            data.address= addressData;
            return data
        } catch (error) {
            throw this.handleError(error)
        }
    }
    async update(id:string,data:any, addressId:string){
        try {
            console.log(data);
            const addressData: any = {
                public_place: data.public_place,
                number: data.number,
                district: data.district,
                CEP: data.cep
            }
            Object.keys(addressData).forEach(value => {
                // eslint-disable-next-line @typescript-eslint/no-dynamic-delete
                delete data[value]
            })

            const  cepinfo = await getCepInfo(data.cep)
            addressData.city_name = cepinfo?.localidade;
            addressData.state=cepinfo?.uf;
            addressData.city_code=cepinfo?.ibge
            addressData.country_code = "1058"
            addressData.country_name ="BRASIL"
            await this.api.put(`/clients/${id}`,data);
            await this.api.put(`/adresses/${addressId}`,addressData)
        } catch (error) {
            console.error(error)
            throw this.handleError(error)
        }
    }

    handleError(error:any): Error{

        if(error instanceof AxiosError){
            switch(error.response?.status){
                case 500:
                    return Error('Erro com servidor, tente novamente mais tarde.');
                case 400:
                    return Error('Algum dado não foi enviado corretamente')
                case 401:
                    return Error('Não autorizado')
            }
        }
        return Error("Oops! Aconteceu algo.")
    }
}