import { Link } from "react-router-dom";
import styled from "styled-components";

export const ShowBox = styled.div<{margin:number}>`
    margin-left: ${props => props.margin + 50}px; 
    margin-right: 50px;

`
export const UnstyledLink = styled(Link)`

    color: inherit;
    text-decoration: inherit;
    cursor: inherit;
  `