import BaseApiService from "../BaseApiService";

export default class AuthService extends BaseApiService {
  constructor() {
    super("login");
  }

  async login(loginData: { email: string; password: string }): Promise<void> {
    try {
      const data = (await this.nfastApi.post<any>("", loginData))
        .data;
        console.log(data);
      localStorage.setItem("token", data.token);
    } catch (error) {
      throw this.handleError(error);
    }
  }
}
