import React from "react";
import { Delete, Edit } from "@mui/icons-material";
import {
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import { Link } from "react-router-dom";

interface LabelInterface {
  name: string;
  label: string;
}
export default function DataTable({
  data,
  labels,
  onDelete,
  disable = false,
}: {
  data: Array<Record<string, unknown>>;
  labels: LabelInterface[];
  onDelete: (id: string) => Promise<void>;
  disable?: boolean;
}): React.ReactElement {
  return (
    <TableContainer>
      <Table>
        <TableHead>
          <TableRow>
            {labels.map((value) => (
              <TableCell
                key={value.label}
                sx={{ fontWeight: 700, fontSize: 25 }}
              >
                {value.label}
              </TableCell>
            ))}
            <TableCell />
          </TableRow>
        </TableHead>
        <TableBody>
          {data.map((value, key) => (
            <TableRow key={key}>
              {labels.map((label, key) => (
                <TableCell key={key} sx={{ fontSize: 20 }}>
                  {value[label.name] as string}
                </TableCell>
              ))}
              <TableCell>
                <Link to={"editar/" + (value._id as string)}>
                  <IconButton color="primary" size="large" disabled={disable}>
                    <Edit />
                  </IconButton>
                </Link>

                <IconButton
                  color="error"
                  size="large"
                  onClick={() => {
                    void onDelete(value._id as string);
                  }}
                  disabled={disable}
                >
                  <Delete />
                </IconButton>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
