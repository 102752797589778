import { useContext } from "react";
import { ErrorContext } from "../Context/ErrorContext";

export default function useSetError(): (error: unknown) => void {
  const {setMessageError} =  useContext(ErrorContext);
  const setError = (error: unknown): void => {
    setMessageError(error);
  };
  return setError;
}
