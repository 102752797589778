import axios from "axios"

type cepInfoDto = {
    logradouro:string,
    complemento:string,
    bairro:string,
    localidade:string,
    uf:string,
    ibge:string,
    gia:string,
    ddd:string,
    siafi:string
}
export const getCepInfo = async (cep:string)=>{
    try {
        cep = cep.replace(/(\d{5})-(\d{1,3})/,'$1$2')
        const {data} =  await axios.get<cepInfoDto>(`https://viacep.com.br/ws/${cep}/json/`);
        return data;
    } catch (error) {
        console.log(error);
    }
}