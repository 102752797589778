import React from "react";
import {
  AppBar,
  Button,
  Drawer,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Toolbar,
} from "@mui/material";
import { Groups, Logout, ReceiptLong } from "@mui/icons-material";
import { Outlet } from "react-router-dom";
import { ShowBox, UnstyledLink } from "./style";
import useAuthenticate from "../../../hooks/useAuthenticate";

export default function EnterpriseDash(): JSX.Element {
  const { logout } = useAuthenticate();

  const menuLinks = [
   
    {
      path: "clientes",
      name: "Clientes",
      icon: <Groups />,
    },
    {
      path: "entregas",
      name: "Entregas",
      icon: <ReceiptLong />,
    },
  ];
  const drawerWidth = 230;
  return (
    <div>
      <AppBar
        sx={{ zIndex: (theme) => theme.zIndex.drawer + 1, position: "sticky" }}
      >
        <Toolbar
          sx={{
            display: "flex",
            justifyContent: "space-between",
            padding: 2,
          }}
        >
          <img
            src="/logoWhite.png"
            height="70px"
            alt="texto com escritas 'Nfast'"
          />
          <Button
            onClick={logout}
            variant="contained"
            color="error"
            startIcon={<Logout />}
          >
            Sair
          </Button>
        </Toolbar>
      </AppBar>
      <Drawer
        sx={{
          width: drawerWidth,
          "& .MuiDrawer-paper": {
            width: drawerWidth,
            boxSizing: "border-box",
          },
        }}
        variant="persistent"
        open={true}
        anchor="left"
      >
        <Toolbar sx={{ padding: 2 }} />
        <List>
          {menuLinks.map((link, index) => (
            <UnstyledLink to={link.path} key={index}>
              <ListItem>
                <ListItemButton>
                  <ListItemIcon>{link.icon}</ListItemIcon>
                  <ListItemText>{link.name}</ListItemText>
                </ListItemButton>
              </ListItem>
            </UnstyledLink>
          ))}
        </List>
      </Drawer>

      <Toolbar />
      <ShowBox margin={drawerWidth}>
        <Outlet />
      </ShowBox>
    </div>
  );
}
