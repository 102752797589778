import React, { useEffect } from "react";
import FloatButton from "../../Components/FloatButton";
import DataTable from "../../Components/DataTable";
import useUsers from "../../hooks/useUsers";
export default function Users(): JSX.Element {
  const {users , deleteUser, getAllUsers} =  useUsers();
  const label = [
    {
      name: "name",
      label: "Nome",
    },
    {
      name: "email",
      label: "Email",
    },
    {
      name: "role",
      label: "Papel",
    },
  ];

  useEffect(()=> {
    void getAllUsers();
  },[])

  return (
    <div>
      <DataTable data={users} labels={label} onDelete={deleteUser} />
      <FloatButton path="criar" />
    </div>
  );
}
