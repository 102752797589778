import { useCallback, useMemo, useState } from "react";
import { type findEmitter } from "../DTOs/emitter.dto";
import useSetError from "./useSetError";
import { EmittersService } from "../services/emitters/EmittersService";
import {
  type formEditEmitter,
  type formEmitter,
} from "../Entities/emitter.entity";
import { useNavigate } from "react-router-dom";

export default function useEmitters(): {
  emitters: findEmitter[];
  emitter?: findEmitter;
  getEmitter: (id: string) => Promise<void>;
  getAll: () => Promise<void>;
  deleteEmitter: (id: string) => Promise<void>;
  create: (emitter: formEmitter) => Promise<void>;
  edit: (emitter: formEditEmitter) => Promise<void>;
} {
  const setError = useSetError();
  const emitterService = useMemo(() => new EmittersService(), []);
  const [emitters, setEmitters] = useState<findEmitter[]>([]);
  const [emitter, setEmitter] = useState<findEmitter | undefined>();
  const navigate = useNavigate();

  const getEmitter = useCallback(
    async (id: string) => {
      try {
        const emitter = await emitterService.findOne(id);
        setEmitter(emitter);
      } catch (error) {
        setError(error);
      }
    },
    [emitterService]
  );
  const getAll = useCallback(async () => {
    try {
      const emitters = await emitterService.getAll();
      setEmitters(emitters);
    } catch (error) {
      setError(error);
    }
  }, [emitterService]);
  const deleteEmitter = async (id: string): Promise<void> => {
    try {
      await emitterService.delete(id);
      setEmitters(emitters.filter((emitter) => emitter._id !== id));
    } catch (error) {
      setError(error);
    }
  };
  const create = async (emitter: formEmitter): Promise<void> => {
    try {
      await emitterService.create(emitter);
      navigate(-1);
    } catch (error) {
      setError(error);
    }
  };
  const edit = async (emitterEdited: formEditEmitter): Promise<void> => {
    try {
      if (emitter === undefined) throw new Error("Emissor não encontrado");
      await emitterService.update(emitter._id, emitterEdited);
      navigate(-1);
    } catch (error) {
      setError(error);
    }
  };
  return {
    emitters,
    emitter,
    getEmitter,
    getAll,
    deleteEmitter,
    create,
    edit,
  };
}
