import * as Yup from 'yup';
import { YupErrors } from '../Consts/YupErrors';
const createEnterpriseValidate = Yup.object().shape({
  name:Yup.string()
  .min(2, YupErrors.shortName)
  .required(YupErrors.required),
  administratorName: Yup.string()
  .min(2,YupErrors.shortName)
  .required(YupErrors.required),
  password: Yup.string().min(8, "A senha deve ter no mínimo 8 caracteres")
  .required(YupErrors.required),
  email: Yup.string()
  .email(YupErrors.email)
  .required(YupErrors.required)
})

export { createEnterpriseValidate};