import styled from "styled-components";
import { Form } from "../../App.style";

export const UserItem =  styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    font-size: 20px;
    margin-right: 20px;
    margin-left: 20px;
`
export const FormUser =  styled(Form)`
    & button {
        height: 56px;
    }
`