import * as Yup from "yup";
import { YupErrors } from "../Consts/YupErrors";
const createUserValidator = Yup.object().shape({
  name: Yup.string().min(2, YupErrors.shortName).required(YupErrors.required),
  email: Yup.string().email(YupErrors.email).required(YupErrors.required),
  password: Yup.string()
    .min(8, "A senha deve ter no minímo 8 caracteres.")
    .required(YupErrors.required),
  role: Yup.string().required(YupErrors.required),
});

const editUserValidate = Yup.object().shape({
  name: Yup.string().min(2, YupErrors.shortName).required(YupErrors.required),
  email: Yup.string().email(YupErrors.email).required(YupErrors.required),
  role: Yup.string().required(YupErrors.required),
});
export { createUserValidator, editUserValidate };
