import React, {
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import DataTable from "../../Components/DataTable";
import OldApiService from "../../services/oldApi/OldApi.service";

export default function customers(): JSX.Element {
  const [customers, setCustomer] = useState<any[]>([]);
  const oldApiService = useMemo(() => new OldApiService(), []);

  const label = [
    {
      name: "company_name",
      label: "Nome",
    },
    {
      name: "document",
      label: "Documento",
    },
  ];

  const getAll = useCallback(async () => {
    const customers = await oldApiService.getAll();
    customers.map((customer: any) => {
      customer._id = customer.id;
      return customer;
    });
    setCustomer(customers);
  }, [oldApiService]);

  useEffect(() => {
    
    void getAll();
  }, [getAll]);
  return (
    <div>
      <DataTable
        data={customers}
        labels={label}
        onDelete={async (id: string) => {
          await Promise.resolve();
        }}
      />
    </div>
  );
}
