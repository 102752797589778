import React, { useState, createContext } from "react";
import { Snackbar } from "@mui/material";

interface ErrorProps {
  setMessageError: (error: unknown) => void;
}
const ErrorContext = createContext<ErrorProps>({
  setMessageError: (): void => {},
});
function ErrorProvider({ children }: { children: JSX.Element }): JSX.Element {
  const [error, setError] = useState("");
  function setMessageError(error: unknown): void {
    if (error instanceof Error) setError(error.message);
    else setError("Oops! Aconteceu Algo.");
  }
  return (
    <ErrorContext.Provider value={{ setMessageError }}>
      <Snackbar
        open={error.length > 0}
        onClose={() => {
          setError("");
        }}
        message={error}
      />
      {children}
    </ErrorContext.Provider>
  );
}

export { ErrorProvider, ErrorContext };
