import React from "react";
import { Navigate, Outlet } from "react-router-dom";
import { useAuth } from "../../hooks/auth.hooks";
interface PrivateRouteProps {
  children?: JSX.Element;
  roles?: string[],
}
export default function PrivateRoute({
  children,
}: PrivateRouteProps): JSX.Element | null | React.ReactElement {
  const authenticated = useAuth();
  if (authenticated) return children != null ? children : <Outlet />;
  return <Navigate to="/login" />;
}
