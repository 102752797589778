import React, { useState } from "react";
import { Formik } from "formik";
import { DivFlex } from "../../../App.style";
import {
  AdministratorPart,
  Form,
  NFastTitle,
  P,
  SideLeftCreateCard,
  SideRightCreateCard,
  TextFieldStyled,
} from "./style";
import { Divider, Typography, Button, Snackbar } from "@mui/material";
import { EnterpriseService } from "../../../services/enterprise/enterprise.service";
import { type enterpriseSubmit } from "../../../DTOs/enterprise.dto";
import { useNavigate, Link } from "react-router-dom";
import { createEnterpriseValidate } from "../../../Validators/enterpriseValidators";

function CreateEnterprise(): JSX.Element {
  const [snackError, setSnackError] = useState("");
  const enterpriseService = new EnterpriseService();
  const navigate = useNavigate();

  async function handleSubmit(data: enterpriseSubmit): Promise<void> {
    try {
      const administrator = await enterpriseService.createEnterprise(data);
      navigate(`/login?email=${administrator.email}`);
    } catch (error) {
      if (error instanceof Error) {
        setSnackError(error.message);
      }
    }
  }

  return (
    <DivFlex>
      <SideLeftCreateCard>
        <NFastTitle>NFAST</NFastTitle>
        <P>
          {" "}
          uma solução
          <br />
          rápida e ágil <br />
          para o seu negócio{" "}
        </P>
      </SideLeftCreateCard>
      <SideRightCreateCard>
        <Formik
          initialValues={{
            name: "",
            administratorName: "",
            password: "",
            email: "",
          }}
          onSubmit={handleSubmit}
          validationSchema={createEnterpriseValidate}
        >
          {({
            values,
            errors,
            touched,
            handleChange,
            handleBlur,
            handleSubmit,
            isSubmitting,
            isValid,
            dirty,
          }) => (
            <Form onSubmit={handleSubmit}>
              <Typography variant="h1" fontSize={40}>
                Crie sua empresa
              </Typography>

              <TextFieldStyled
                variant="outlined"
                label="Nome da sua empresa"
                name="name"
                error={errors.name != null && touched.name}
                helperText={errors.name}
                value={values.name}
                onChange={handleChange}
                onBlur={handleBlur}
              />
              <Divider sx={{ marginBlock: 2 }} />
              <AdministratorPart>
                <Typography variant="h3" fontSize={30}>
                  Administrador
                </Typography>

                <TextFieldStyled
                  variant="outlined"
                  size="medium"
                  label="Nome do administrador"
                  name="administratorName"
                  error={
                    errors.administratorName != null &&
                    touched.administratorName
                  }
                  helperText={errors.administratorName}
                  value={values.administratorName}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />

                <TextFieldStyled
                  variant="outlined"
                  type="email"
                  label="email"
                  name="email"
                  error={errors.email != null && touched.email}
                  helperText={errors.email}
                  value={values.email}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
                <TextFieldStyled
                  variant="outlined"
                  type="password"
                  label="Senha"
                  name="password"
                  error={errors.password != null && touched.password}
                  helperText={errors.password}
                  value={values.password}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
              </AdministratorPart>

              <Snackbar
                open={snackError.length > 0}
                onClose={() => {setSnackError("")}}
                message={snackError}
              />
              <Button
                sx={{ marginTop: 10, height: 80 }}
                variant="contained"
                disabled={!(isValid && !isSubmitting && dirty)}
                type="submit"
              >
                Cadastrar
              </Button>
              <Link to="/login">
                <Button
                  sx={{ marginTop: 2, height: 80, width: "100%" }}
                  variant="outlined"
                  color="secondary"
                  type="button"
                >
                  Login
                </Button>
              </Link>
            </Form>
          )}
        </Formik>
      </SideRightCreateCard>
    </DivFlex>
  );
}
export default CreateEnterprise;
