import { createUser, editUser, findUser } from "../../DTOs/user.dto";
import BaseApiService from "../BaseApiService"

export default class UsersService extends BaseApiService {

    constructor(){
        super('users');
    }

    async createUser(user:createUser){
        try {
            
            await this.nfastApi.post('',user)
        } catch (error) {
            throw this.handleError(error)
        }
    }
    async findOneUser(id:string): Promise<findUser>{
        try {
            const { data } = await this.nfastApi.get(`${id}`);
            return data; 
        } catch (error) {
            throw this.handleError(error);
        }
    }
    async deleteUser(id:string){
        try{
            await this.nfastApi.delete(`/${id}`)
        } catch(error){
            throw this.handleError(error);
        }
    }
    async updateUser(id:string, data:editUser){
        try {
            await this.nfastApi.put(`${id}`,data)
        } catch (error) {
            throw this.handleError(error);
        }
    }
}