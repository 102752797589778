import { useContext } from "react";
import { AuthContext } from "../Context/AuthContext";
import { useNavigate } from "react-router-dom";

export default function useAuthenticate(): {login:() => void, logout: ()=> void, authenticated: boolean} {
  const { authenticate, authenticated } = useContext(AuthContext);
  const navigate =  useNavigate();
  const login = (): void => {
    authenticate(true);
    navigate('/dash')
  };
  const logout = (): void => {
    authenticate(false);
    navigate('/login')
  }
  return { login, logout, authenticated };
}
