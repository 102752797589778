import React, { useState, createContext, useEffect } from "react";
interface AuthContextProps {
  authenticated: boolean;
  authenticate: (isLogin: boolean) => void;
  getRole: () => string;
}
const AuthContext = createContext<AuthContextProps>({
  authenticated: false,
  authenticate: () => {},
  getRole: () => "role",
});

function AuthProvider({ children }: { children: JSX.Element }): JSX.Element {
  const [authenticated, setAuthenticated] = useState(true);

  useEffect(() => {
    const token = localStorage.getItem("token");

    if (token == null) {
      setAuthenticated(false);
    } else {
      setAuthenticated(true);
    }
  }, []);

  function getRole(): string {
    // const role =  decode(localStorage.getItem('token')!) as JwtPayload;

    return "seila";
  }
  const authenticate = (isLogin: boolean): void => {
    if (!isLogin) localStorage.removeItem("token");
    setAuthenticated(isLogin)
  };
  return (
    <AuthContext.Provider value={{ authenticated, authenticate, getRole }}>
      {children}
    </AuthContext.Provider>
  );
}

export { AuthContext, AuthProvider };
