import { Typography, TextField } from "@mui/material";
import styled from "styled-components";

export const SideLeftCreateCard = styled.div`
    display: flex;
    flex-direction: column;
    align-items: end;
    width: 60%;
    margin-right: 30px;
    justify-content:center;
`
export const Form = styled.form`
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-inline: 40px;
  gap: 10px;
  height: 100vh;
`
export const SideRightCreateCard = styled.div`
    background-color: #FFFFFF;
    width:60%;
    border-radius: 68px 0px 0px 73px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: stretch;
`

export const TextFieldStyled = styled(TextField)`
    margin-top: 10px !important ;
`
export const TitleCreateEnterprise = styled(Typography)`
    font-size: 50px;
`
export const AdministratorPart = styled.div`
margin-top: 30px;
margin-left: 50px;
margin-right: 90px;
display: flex;
flex-direction: column;
`

export const NFastTitle = styled.h1`
line-height: 190px;
font-size: 157px;
color: rgba(0,0,0,0);
-webkit-text-stroke: 1px white;
margin:0;
font-style: italic;
font-weight: 800;
`

export const P = styled.p`
color: white;
font-size: 67px;
text-align:right;
font-weight: 600;
margin:0px;
`