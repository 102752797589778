import { AxiosError, type AxiosInstance } from "axios";
import { NfastApi } from "./enterprise/nfastApi";

export default class BaseApiService {
    nfastApi: AxiosInstance;
    
    constructor(route:string){
        this.nfastApi = NfastApi(route);
    }

    handleError(error:any): Error{

        if(error instanceof AxiosError){
            switch(error.response?.status){
                case 500:
                    return Error('Erro com servidor, tente novamente mais tarde.');
                case 400:
                    return Error('Algum dado não foi enviado corretamente')
                case 401:
                    return Error('Não autorizado')
            }
        }
        return Error("Oops! Aconteceu algo.")
    }
}