import styled from "styled-components";

export const DivFlex = styled.div`

display: flex;
justify-content:space-between;
align-items:stretch;
background-color: #008B7F;
height: 100vh;
`
export const Form =  styled.form`
  gap: 20px;
  display: flex;
  flex-direction: column;
`